<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <CropStatistics />
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import CropStatistics from '@/components/dashboard/CropStatistics.vue'

export default {
  name: 'SupervisorGraphCrops',

  middleware: ['auth', 'supervisor'],

  components: {
    CropStatistics
  },

  metaInfo () {
    return {
      title: 'Crops Graph'
    }
  },

  mounted () {
    core.index()
  }
}
</script>
